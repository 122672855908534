import Button from '@components/ui/Button';
import Container from '@components/ui/Container';
import Logo from '@components/ui/Logo';
import NavLink from '@components/ui/NavLink';
import Plus from '@components/ui/Plus';
import routes from '@data/routes';
import handleScrollBehavior from '@utils/handleScrollBehavior';
import useMediaQuery from 'hooks/useMediaQuery';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import Bar from './assets/Bar';
import Mobilemenu from './modules/MobileMenu';

const HeaderMain = styled.header`
  position: fixed;
  z-index: 40;
  top: 0;
  width: 100%;
  transition: 0.25s linear;
  background: ${({ theme, color }) => {
    if (color === 'black') {
      return theme.colors.primary.black;
    }

    return theme.colors.secondary.silver_1000;
  }};
  padding: 23px 0;

  @media screen and (max-width: 790px) {
    background: ${({ theme }) => theme.colors.secondary.silver_1000};
  }
`;

const HeaderContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  padding-left: 64px;
  display: flex;
  justify-content: ${(props) =>
    props.isContacts ? 'space-between' : 'flex-end'};
  align-items: center;

  @media screen and (max-width: 800px) {
    padding-left: 20px;
  }

  @media screen and (max-width: 790px) {
    padding-left: 70px;
  }

  @media screen and (max-width: 450px) {
    padding-left: 10px;
  }
`;

const StyledLink = styled.a`
  font-weight: 400;
  font-size: 16px;
  color: ${({ headerColor, theme }) =>
    headerColor === 'black'
      ? theme.colors.secondary.silver_1000
      : theme.colors.primary.orange};
  display: flex;
  align-items: center;
  gap: 7px;
  transition: 0.2s linear;

  @media screen and (max-width: 790px) {
    color: ${({ theme }) => theme.colors.primary.orange};
  }

  @media screen and (max-width: 330px) {
    font-size: 14px;
  }

  &:hover {
    transition: 0.2s linear;
    color: ${({ headerColor, theme }) =>
      headerColor === 'black'
        ? theme.colors.secondary.silver_800
        : theme.colors.primary.hover_orange};
    cursor: pointer;

    svg path {
      fill: ${(props) => props.hoverColor};
      transition: 0.2s linear;
    }

    @media screen and (max-width: 790px) {
      color: ${({ theme }) => theme.colors.primary.hover_orange};
    }
  }
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  column-gap: 100px;
  white-space: nowrap;

  @media screen and (max-width: 1200px) {
    column-gap: 30px;
  }

  @media screen and (max-width: 790px) {
    display: none;
  }
`;

const BarButton = styled(Button)`
  display: none;

  @media screen and (max-width: 790px) {
    display: flex;

    svg path {
      stroke: ${({ theme, headerColor }) =>
        headerColor === 'black'
          ? theme.colors.secondary.silver_1000
          : theme.colors.primary.black};

      @media screen and (max-width: 790px) {
        stroke: ${({ theme }) => theme.colors.primary.black};
      }
    }
  }
`;

const Header = ({ hideLinks = false, color }) => {
  const { t } = useTranslation('routes');
  const { pathname, asPath } = useRouter();

  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const isContacts = pathname !== '/contacts';
  const isBreakpoint = useMediaQuery(790);

  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (showMobileMenu && isBreakpoint) {
        window.document.body.style.position = 'fixed';
      } else {
        window.document.body.style.position = 'relative';
      }
    }
  }, [showMobileMenu, isBreakpoint]);

  return (
    <HeaderMain color={color}>
      <HeaderContainer>
        <Logo color={color} />
        {!hideLinks && (
          <ButtonsWrapper isContacts={isContacts}>
            {isContacts && (
              <Link href="/contacts" passHref>
                <StyledLink headerColor={color}>
                  <Plus />
                  {t('customers')}
                </StyledLink>
              </Link>
            )}
            <Buttons>
              {routes.map(({ title, id, link }) => (
                <Link key={id} href={link} passHref>
                  <NavLink
                    onClick={(e) => handleScrollBehavior(e, link)}
                    headerColor={color}
                    isActive={link === asPath}
                  >
                    {t(title)}
                  </NavLink>
                </Link>
              ))}
            </Buttons>
            <BarButton onClick={toggleMobileMenu} headerColor={color}>
              <Bar />
            </BarButton>
          </ButtonsWrapper>
        )}
      </HeaderContainer>

      {/* mobile menu */}
      <Mobilemenu toggle={toggleMobileMenu} show={showMobileMenu} />
    </HeaderMain>
  );
};

export default Header;
