const Bar = ({ className }) => (
  <svg
    className={className}
    width="28"
    height="28"
    viewBox="0 0 42 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 2H42M0 14H42M0 26H42" stroke="currentcolor" strokeWidth="4" />
  </svg>
);

export default Bar;
