import useMediaQuery from 'hooks/useMediaQuery';
import Link from 'next/link';
import styled from 'styled-components';

import logoBlack from './assets/logoBlack.svg';
import logoWhite from './assets/logoWhite.svg';

const StyledLink = styled.a`
  height: 35px;
`;

const StyledImg = styled.img`
  width: 110px;
  height: 35px;
`;

const Logo = ({ className, color }) => {
  const isMobileVersion = useMediaQuery(790);

  return (
    <Link href="/" className={className} passHref>
      <StyledLink>
        <StyledImg
          src={
            color === 'black' && !isMobileVersion
              ? logoWhite.src
              : logoBlack.src
          }
        />
      </StyledLink>
    </Link>
  );
};

export default Logo;
