import styled from 'styled-components';

const StyledContainer = styled.div`
  max-width: 1440px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;

  @media screen and (min-width: 790px) {
    padding-left: 60px;
    padding-right: 60px;
  }

  @media screen and (min-width: 1200px) {
    padding-left: 120px;
    padding-right: 120px;
  }
`;

const Container = ({ children, className }) => (
  <StyledContainer className={className}>{children}</StyledContainer>
);

export default Container;
