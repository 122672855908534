import styled from 'styled-components';

const NavLink = styled.a`
  font-size: 16px;
  font-family: 'Pragmatica';
  color: ${({ theme, headerColor, isActive }) => {
    if (headerColor === 'black') {
      return theme.colors.secondary.silver_900;
    }

    if (headerColor === 'white' && isActive) {
      return theme.colors.primary.orange;
    }

    return theme.colors.primary.black;
  }};
  position: relative;
  display: block;
  &::before {
    content: '';
    position: absolute;
    width: ${(props) => (props.isActive ? '100%' : 0)};
    height: 2px;
    bottom: -5px;
    left: 0;
    background: ${({ theme }) => theme.colors.primary.orange};
    visibility: ${(props) => (props.isActive ? 'visible' : 'hidden')};
    transition: all 0.25s ease-in-out;
  }
  &:hover {
    color: ${({ headerColor, theme }) =>
      headerColor === 'black'
        ? theme.colors.secondary.silver_900
        : theme.colors.primary.orange};
    &::before {
      visibility: visible;
      width: 100%;
    }
  }
`;

export default NavLink;
