import Button from '@components/ui/Button';
import Logo from '@components/ui/Logo';
import routes from '@data/routes';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useSwipeable } from 'react-swipeable';
import styled from 'styled-components';

import line from '../assets/line.png';
import Cross from '../assets/Сross';

const MobileMenuWrapper = styled.div`
  position: fixed;
  z-index: 100;
  transition: 0.4s linear;
  top: 0;
  right: ${({ show }) => (show ? 0 : '-150%')};
  width: 100vw;
  height: 100vh;
  background: ${({ theme }) => theme.colors.secondary.silver_1000};

  @media screen and (min-width: 790px) {
    display: none;
  }
`;

const MobileContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 80px;

  @media screen and (max-width: 790px) {
    padding: 0 40px;
  }
`;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
`;

const StyledLink = styled.a`
  font-weight: 700;
  font-size: 30px;
  color: ${({ theme }) => theme.colors.primary.black};
`;

const ContactsContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
  z-index: 12;
  flex-direction: column;
`;

const ContactsBlock = styled.div`
  display: flex;
  min-width: 184px;
  flex-direction: column;
  margin-bottom: 20px;

  &:only-child {
    margin-bottom: 90px;
  }
`;

const ContactsBlockLink = styled.a`
  color: ${({ theme }) => theme.colors.primary.orange};
`;

const ContactsBlockTitle = styled.span`
  font-size: 16px;
  letter-spacing: 0.04em;
  line-height: 174%;
  color: ${({ theme }) => theme.colors.primary.black};
`;

const Line = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 80%;
  height: 60%;
  background: url(${line.src}) no-repeat right bottom;
  background-size: contain;
  opacity: 0.3;
  pointer-events: none;
`;

const MobileMenuHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 26px;
`;

const Mobilemenu = ({ show, toggle }) => {
  const { t } = useTranslation('routes');
  const { locale } = useRouter();

  const swipeHandle = useSwipeable({ onSwipedRight: toggle });

  return (
    <MobileMenuWrapper {...swipeHandle} show={show}>
      <MobileContainer>
        <MobileMenuHeader>
          <Logo />
          <Button onClick={toggle}>
            <Cross />
          </Button>
        </MobileMenuHeader>
        <LinksContainer>
          {routes.map(({ title, id, link }) => (
            <Link href={link} passHref key={id}>
              <StyledLink onClick={toggle} key={id}>
                {t(title)}
              </StyledLink>
            </Link>
          ))}
        </LinksContainer>
        <ContactsContainer>
          <ContactsBlock>
            <ContactsBlockTitle>{t('cooperation')}</ContactsBlockTitle>
            <ContactsBlockLink href="mailto:hello@fojin.tech">
              hello@fojin.tech
            </ContactsBlockLink>
          </ContactsBlock>
          {locale === 'ru' ? (
            <ContactsBlock>
              <ContactsBlockTitle>{t('employment')}</ContactsBlockTitle>
              <ContactsBlockLink href="mailto:welcome@fojin.tech">
                welcome@fojin.tech
              </ContactsBlockLink>
            </ContactsBlock>
          ) : null}
        </ContactsContainer>
      </MobileContainer>
      <Line />
    </MobileMenuWrapper>
  );
};

export default Mobilemenu;
