const Cross = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2 2L26 26M2 26L26 2" stroke="currentcolor" strokeWidth="4" />
  </svg>
);

export default Cross;
