const handleScrollBehavior = (e, link) => {
  const linkWithoutSlash = link.slice(1);
  const elementById = document.getElementById(linkWithoutSlash.slice(1));

  if (
    linkWithoutSlash.startsWith('#') &&
    linkWithoutSlash !== '#form' &&
    elementById
  ) {
    e.preventDefault();

    elementById.scrollIntoView({
      behavior: 'smooth',
    });
  }
};

export default handleScrollBehavior;
