import styled from 'styled-components';

const Button = styled.button`
  border: none;
  background: transparent;

  align-items: center;
  justify-content: center;

  img {
    width: 24px;
    height: 24px;
  }
  &:hover {
    cursor: pointer;
  }

  svg path {
    stroke: ${({ theme, headerColor }) =>
      headerColor === 'black'
        ? theme.colors.secondary.silver_1000
        : theme.colors.primary.black};

    @media screen and (max-width: 790px) {
      stroke: ${({ theme }) => theme.colors.primary.black};
    }
  }
`;

export default Button;
